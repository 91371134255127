import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { fetchApi } from "../../common/api";
import ApiUrls from "../../common/urls";
import { getHeaders } from "../../common/utils";
import { Note } from "../students/student";
import { Subject } from "./class";
import BeeLoader from "../../layout/loader";
import * as Routes from "../../routing/routes";
import CancelButton from "../components/cancelButton";
import SubmitButton from "../components/submitButton";
import Button from "../components/button";
import InputLabelValidation from "../components/inputLabelValidation";
import Select from "../components/select";
interface Dates {
  begin: string;
  end: string;
}

const Labels = {
  Bareme: "Barème",
  Coefficient: "Coefficient",
  Add: "Valider",
  ListSubjects: "Liste des matières",
  IsRequired: "Ce champ est requis",
  CorrectForm: "Veuillez corriger les erreurs",
  Cancel: "Annuler",
  Comment: "Libellé",
  Date: "Date devoir",
  Bonus: "Bonus (facultatif)",
  RequiredField: "Champ obligatoire",
  BonusExplanation:
    "Les points bonus seront pris en compte comme ceci : ((note * coef) + bonus)/somme des coefficients",
};

interface AddWorkProps {
  addWork: (n: Note) => void;
  classId?: number;
  cancel: () => void;
  baremeProp?: number | undefined;
  subjectProp?: Subject;
  dateProp?: string;
  coefficient?: number | undefined;
  currentNote?: Note;
  yearSetting?: number;
}

const AddWork = ({
  addWork,
  classId,
  cancel,
  currentNote,
  yearSetting,
}: AddWorkProps) => {
  const [subjects, setSubjects] = useState<Subject[]>();
  const [bareme, setBareme] = useState<number | undefined>(
    currentNote ? currentNote.scale : undefined
  );
  const [coeff, setCoeff] = useState<number | undefined>(
    currentNote ? currentNote.coefficient : undefined
  );
  const [date, setDate] = useState<string | undefined>(
    currentNote ? currentNote.calendarDate : undefined
  );
  const [selectSubject, setSelectedSubject] = useState<number | undefined>(
    currentNote ? currentNote.subjectId : undefined
  );
  const [isFormValid, setIsFormValid] = useState(false);
  const [begin, setBegin] = useState<string | undefined>();
  const [end, setEnd] = useState<string | undefined>();
  const [comment, setComment] = useState<string | undefined>(
    currentNote ? currentNote.comment : undefined
  );
  const [bonus, setBonus] = useState<number>(
    currentNote ? currentNote.bonus : 0
  );
  const [errors, setErrors] = useState<any>({});
  const { addToast } = useToasts();
  const [isFetchingSubjects, setIsFetchingSubjects] = useState(false);
  const [isFetchingDates, setIsFetchingDates] = useState(false);

  const fetchSubjects = async (): Promise<Subject[] | null> => {
    setIsFetchingSubjects(true);
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Classes}/${classId}/subjects`;
    return await fetchApi<Subject[]>(url);
  };

  const fetchDates = async (): Promise<Dates | null> => {
    setIsFetchingDates(true);
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Settings}/year/dates/${yearSetting}`;
    return await fetchApi<Dates>(url);
  };

  useEffect(() => {
    if (classId) {
      fetchSubjects().then((response) => {
        setIsFetchingSubjects(false);
        if (response) {
          setSubjects(response);
        }
      });
    }

    fetchDates().then((response) => {
      setIsFetchingDates(false);
      if (response) {
        setBegin(response.begin);
        setEnd(response.end);
      }
    });
  }, [classId, yearSetting]);

  const displaySubjects = (): JSX.Element[] | undefined => {
    if (subjects) {
      const elements = subjects.map((s: Subject) => {
        return (
          <option key={s.id} value={s.id}>
            {s.name}
          </option>
        );
      });
      return elements;
    }
    return undefined;
  };

  const onSelectSubject = (id: string) => {
    if (subjects) {
      const subject = subjects.find((s) => s.id === parseInt(id));
      if (subject) {
        setSelectedSubject(subject.id);
      }
    }
  };

  const validate = (): boolean => {
    setIsFormValid(true);
    setErrors({});
    let errorsTemp: any = {};
    let isValid = true;
    if (!date) {
      errorsTemp["date"] = Labels.IsRequired;
    }
    if (!selectSubject) {
      errorsTemp["selectSubject"] = Labels.IsRequired;
    }
    if (bareme === 0 || !bareme) {
      errorsTemp["bareme"] = Labels.IsRequired;
    }
    if (coeff === undefined) {
      errorsTemp["coeff"] = Labels.IsRequired;
    }

    isValid = Object.entries(errorsTemp).length === 0;
    setIsFormValid(isValid);
    setErrors(errorsTemp);
    return isValid;
  };

  const submit = async (e: any) => {
    e.preventDefault();

    if (!validate()) {
      addToast(Labels.CorrectForm, { appearance: "error", autoDismiss: true });
      return;
    }
    if (selectSubject) {
      const note = {
        id: 0,
        value: -1,
        scale: bareme,
        coefficient: coeff,
        subjectId: selectSubject,
        date: date,
        isBlocked: false,
        subject: "",
        comment: comment,
        classeAverage: 0,
        bonus: bonus,
        isAbsent: false,
        dateLabel: "",
        subjectAverageValue: 0,
        classSubjectAverageValue: 0,
      };
      addWork(note);
    }
  };

  const updateCoeff = (value: string) => {
    if (value === "") {
      value = "0";
    }
    const newValue = value.replace(",", ".");

    const coeff = parseFloat(newValue);
    setCoeff(coeff);
  };
  const isLoading = () => {
    return isFetchingDates || isFetchingSubjects;
  };
  return (
    <>
      <div className="row">
        {isLoading() && (
          <div className="flex justify-center">
            <BeeLoader />
          </div>
        )}
        {!isLoading() && (
          <>
            <form onSubmit={submit}>
              <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                <div>
                  <h2 className="text-base font-semibold leading-7 text-gray-900">
                    {
                      currentNote && "Modification d'un devoir"
                    }
                    {
                      !currentNote && "Ajout d'un devoir"
                    }
                  </h2>
                  <p className="mt-1 text-sm leading-6 text-gray-600">
                    Veuillez compléter les informations suivantes
                  </p>
                  <p className="mt-1 text-sm leading-6 text-gray-600">
                    {"(*)"} {Labels.RequiredField}
                  </p>
                  <p className="mt-1 text-sm leading-6 text-gray-600">
                    {"(**)"} {Labels.BonusExplanation}
                  </p>
                </div>
                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2 ">
                  <div className="sm:col-span-3">
                    <Select
                      hasError={errors["selectSubject"] !== undefined}
                      data={subjects}
                      errorLabel={errors["selectSubject"]}
                      value={selectSubject}
                      label="Matière *"
                      selectLabel="Choisissez une matière"
                      onChange={(e) => onSelectSubject(e.toString())}
                    />
                  </div>

                  <InputLabelValidation
                    errorLabel={errors["date"]}
                    hasError={errors["date"] !== undefined}
                    type="date"
                    min={begin}
                    max={end}
                    inputValue={date}
                    onChangeValue={(e) => setDate(e.currentTarget.value)}
                    label={`${Labels.Date} *`}
                  />
                  <InputLabelValidation
                    errorLabel={errors["bareme"]}
                    hasError={errors["bareme"] !== undefined}
                    type="number"
                    min={1}
                    pattern="[0-9]+([\,][0-9]+)?"
                    inputValue={bareme}
                    onChangeValue={(e) =>
                      setBareme(parseFloat(e.currentTarget.value))
                    }
                    label={`${Labels.Bareme} *`}
                  />
                  <InputLabelValidation
                    errorLabel={errors["coeff"]}
                    hasError={errors["coeff"] !== undefined}
                    type="number"
                    pattern="[0-9]+([\,][0-9]+)?"
                    inputValue={coeff}
                    onChangeValue={(e) => updateCoeff(e.currentTarget.value)}
                    label={`${Labels.Coefficient} *`}
                  />
                  <InputLabelValidation
                    errorLabel={errors["comment"]}
                    hasError={false}
                    inputValue={comment}
                    onChangeValue={(e) => setComment(e.currentTarget.value)}
                    label={`${Labels.Comment}`}
                  />
                  <InputLabelValidation
                    errorLabel={errors["bonus"]}
                    hasError={errors["bonus"] !== undefined}
                    type="number"
                    pattern="[0-9]+([\,][0-9]+)?"
                    inputValue={bonus}
                    onChangeValue={(e) =>
                      setBonus(parseFloat(e.currentTarget.value))
                    }
                    label={`${Labels.Bonus}**`}
                  />
                </div>
              </div>
              <div className="mt-6 flex items-center justify-end gap-x-6">
                <CancelButton onCancelClick={cancel} />
                <SubmitButton />
              </div>
            </form>
          </>
        )}
      </div>
    </>
  );
  // <div className='row mb-2'>
  //     <form>
  //         <div className='form-group'>
  //             <div className='row'>
  //                 <div className='col-xs-12 col-md-3'>
  //                     <div className='form-floating' >
  //                         <select className='form-select'
  //                             value={selectSubject}
  //                             aria-label={Labels.ListSubjects}

  //                             onChange={(e) => onSelectSubject(e.currentTarget.value)}>
  //                             <option>Choix matières </option>
  //                             {
  //                                 displaySubjects()
  //                             }
  //                         </select>

  //                         <label className='asterisque'>{Labels.ListSubjects}</label>
  //                     </div>
  //                     {
  //                         errors['selectSubject'] &&
  //                         <span className='error'>{errors['selectSubject']}</span>
  //                     }

  //                 </div>
  //                 <div className='col-xs-12 col-md-3'>
  //                     <div className='form-floating' >
  //                         <input type="date" className='form-control'
  //                             value={date}
  //                             min={begin}
  //                             max={end}
  //                             onKeyDown={e => {
  //                                 e.preventDefault();
  //                             }}
  //                             placeholder={Labels.Date}
  //                             onChange={e => setDate(e.currentTarget.value)} />
  //                         <label className='asterisque'>{Labels.Date}</label>
  //                     </div>
  //                     {
  //                         errors['date'] &&
  //                         <span className='error'>{errors['date']}</span>
  //                     }
  //                 </div>

  //             </div>
  //         </div>
  //         <div className='form-group'>
  //             <div className='row'>

  //                 <div className='col-xs-12 col-md-3'>
  //                     <div className='form-floating' >
  //                         <input type="number" className='form-control' value={bareme}
  //                             min={1}
  //                             pattern='[0-9]+([\,][0-9]+)?'
  //                             onChange={e => setBareme( parseFloat(e.currentTarget.value))} placeholder={Labels.Bareme} />
  //                         <label className='asterisque'>{Labels.Bareme}</label>
  //                     </div>
  //                     {
  //                         errors['bareme'] &&
  //                         <span className='error'>{errors['bareme']}</span>
  //                     }
  //                 </div>
  //                 <div className='col-xs-12 col-md-3'>
  //                     <div className='form-floating' >
  //                         <input type="number" step='0.01' className='form-control'
  //                             min={0}
  //                             pattern='[0-9]+([\,][0-9]+)?'
  //                             value={coeff} onChange={e => updateCoeff(e.currentTarget.value)} placeholder={Labels.Coefficient} />
  //                         <label className='asterisque'>{Labels.Coefficient}</label>
  //                     </div>
  //                     {
  //                         errors['coeff'] &&
  //                         <span className='error'>{errors['coeff']}</span>
  //                     }
  //                 </div>
  //                 <div className='col-xs-12 col-md-3'>
  //                     <div className='form-floating' >
  //                         <input type="text" className='form-control' value={comment}
  //                             onChange={e => setComment(e.currentTarget.value)} placeholder={Labels.Comment} />
  //                         <label >{Labels.Comment}</label>
  //                     </div>

  //                 </div>
  //                 <div className='col-xs-12 col-md-3'>
  //                     <div className='form-floating' >
  //                         <input type="number" step='0.5' min={0} className='form-control' value={bonus}
  //                             onChange={e => setBonus(parseFloat(e.currentTarget.value))} placeholder={Labels.Bonus} />
  //                         <label className='double'>{Labels.Bonus}</label>
  //                     </div>

  //                 </div>
  //             </div>

  //         </div>
  //         <div className='form-group '>
  //             <div className='text-center'>
  //                 <button className='btn mr-5 col-md-2' type='button' onClick={submit} > <i className='icon-save'></i></button>
  //                 <button className='btn col-md-2' type='button' onClick={cancel} ><i className='icon-undo'></i></button>

  //             </div>
  //         </div>
  //         <div className='form-group'>
  //             <div className='row'>
  //                 <div className='row ml-2'>
  //                     {'(*)'} {Labels.RequiredField}
  //                 </div>
  //                 <div className='row ml-2'>
  //                     {'(**)'} {Labels.BonusExplanation}
  //                 </div>
  //             </div>
  //         </div>
  //     </form>

  // </div>
};

export default AddWork;
